
import Vue from "vue";
import {AssignmentCoordinationTemplate} from "mp-common/src/types/entities/assignment-coordination-template";
import {User} from "mp-common/src/types/entities/user";
import {navigate, routes} from "@/router/routes";
import {TypeOf} from "io-ts";
import {assignmentCoordination} from "mp-common/src/types/api/assignmentCoordinationApi";

export default Vue.component("AssignmentCoordinationTable", {
  name: 'AssignmentCoordinationTable',
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array as () => TypeOf<typeof assignmentCoordination["getAll"]["returns"]>,
      required: true
    },
    allUsers: {
      type: Array as () => User[],
      required: true,
    },
    hasAssignmentCoordinationRole: {
      type: Boolean as () => boolean,
      required: true,
    },
    changeActiveStatus: {
      type: Function as unknown as () => (templateId: number, active: boolean) => Promise<void>,
      required: true,
    },
    editFunction: {
      type: Function as unknown as () => (template: AssignmentCoordinationTemplate) => void
    }
  },
  data: () => ({
    selectedUser: "",
    navigate,
    routes,
  }),
  methods: {
    answerTemplate(templateId: number) {
      if (this.selectedUser.length > 0 && this.hasAssignmentCoordinationRole) {
        const fullSelectedUser = this.allUsers.find((item) => this.selectedUser.includes(item.firstName) && this.selectedUser.includes(item.lastName));
        if (!fullSelectedUser) {
          return;
        }
        return navigate(routes.assignmentCoordinationEdit, {id: templateId, userId: fullSelectedUser.id})
      } else {
        return navigate(routes.assignmentCoordinationEdit, {id: templateId})
      }
    }
  },
  template: `
    <md-table md-card style="margin-bottom: 20px;">
    <md-table-toolbar>
      <h1 class="md-title">{{title}}</h1>
    </md-table-toolbar>
    <md-table-row>
      <md-table-head>Name</md-table-head>
      <md-table-head>Beschreibung</md-table-head>
      <md-table-head>Aktionen</md-table-head>
    </md-table-row>
    <md-table-row v-for="(item, index) in items" :key="index">
      <md-table-cell><h3>{{ item.template.name }}</h3></md-table-cell>
      <md-table-cell>{{ item.template.description }}</md-table-cell>
      <md-table-cell>
        <md-autocomplete v-if="hasAssignmentCoordinationRole" v-model="selectedUser"
                         :md-options="allUsers.map((entry) => entry.lastName + ', ' + entry.firstName)">
          <label>Für wen ausfüllen</label>
        </md-autocomplete>
        <md-button style="margin-left: 0" v-on:click="answerTemplate(item.template.id)">
          Ausfüllen
        </md-button>
        <md-button v-if="hasAssignmentCoordinationRole"
                   style="margin-left: 0"
                   v-on:click="navigate(routes.assignmentCoordinationResult, {id: item.template.id})">
          Ergebnisse
        </md-button>
        <md-button v-if="hasAssignmentCoordinationRole" v-on:click="changeActiveStatus(item.template.id, !item.template.isActive)">
          {{item.template.isActive ? 'Deaktivieren' : 'Aktivieren'}}
        </md-button>
        <md-button v-if="editFunction !== undefined" v-on:click="editFunction(item.template)">
          Bearbeiten
        </md-button>
      </md-table-cell>
    </md-table-row>
    <md-table-row v-if="items.length === 0">
      <md-table-cell></md-table-cell>
      <md-table-cell>Keine Einträge</md-table-cell>
      <md-table-cell></md-table-cell>
    </md-table-row>
    </md-table>`
})
