








































import Vue from 'vue';
import {Backend} from "api-frontend";
import {Roles, User} from 'mp-common/src/types/entities/user';
import {assignmentCoordination} from "mp-common/src/types/api/assignmentCoordinationApi";
import "@/components/dialogs/ModifyAssignmentCoordination.vue"
import {
  AssignmentCoordinationDate,
  AssignmentCoordinationTemplate
} from "mp-common/src/types/entities/assignment-coordination-template";
import {DateTimeFormatter} from "js-joda";
import {AssignmentCoordinationEditTemplate} from "@/types/assignment-coordination-edit-template";
import {formatTime} from "@/helpers/format-date-time";
import {hasRoles} from "mp-common/src/helper/permissions";
import {TypeOf} from "io-ts";
import {users} from 'mp-common/src/types/api/usersApi'
import "@/components/subComponents/AssignmentCoordinationTable"

export default Vue.extend({
  name: 'AssignmentCoordinationOverview',
  props: {
    user: Object as () => User,
    backend: Backend
  },
  data() {
    return {
      activeAnsweredCoordinations: [] as TypeOf<typeof assignmentCoordination["getAll"]["returns"]>,
      activeNewCoordinations: [] as TypeOf<typeof assignmentCoordination["getAll"]["returns"]>,
      inactiveCoordinations: [] as TypeOf<typeof assignmentCoordination["getAll"]["returns"]>,
      showCreateCoordination: false,
      currentEditTemplate: undefined as undefined | AssignmentCoordinationEditTemplate,
      allUsers: [] as Omit<User, "password">[],
      selectedUser: "",
      dataFetched: false,
    }
  },
  created: async function () {
    await this.fetchActiveCoordinations()
    if (hasRoles(this.user, Roles.ASSIGNMENT_COORDINATOR)) {
      await this.fetchAllUsers();
    }
    this.dataFetched = true;
  },
  methods: {
    async fetchActiveCoordinations() {
      const result = await this.backend.query(assignmentCoordination.getAll, undefined);
      this.activeAnsweredCoordinations = result?.filter((entry) => entry.answers && entry.template.isActive).sort((item, next) => next.template.id - item.template.id) ?? [];
      this.activeNewCoordinations = result?.filter((entry) => !entry.answers && entry.template.isActive).sort((item, next) => next.template.id - item.template.id) ?? [];
      this.inactiveCoordinations = result?.filter((entry) => !entry.template.isActive).sort((item, next) => next.template.id - item.template.id) ?? [];
    },
    async fetchAllUsers() {
      this.allUsers = await this.backend.query(users.getAll, undefined) ?? [];
    },
    hasAssignmentCoordinationRole() {
      return hasRoles(this.user, Roles.ASSIGNMENT_COORDINATOR);
    },
    onCoordinationCreated: async function() {
      this.showCreateCoordination = false;
      this.currentEditTemplate = undefined;
      await this.fetchActiveCoordinations();
    },
    onCoordinationEditCancel() {
      this.showCreateCoordination = false;
      this.currentEditTemplate = undefined;
    },
    async deleteTemplate(id: number) {
      await this.backend.query(assignmentCoordination.deleteTemplate, {
        id
      })
      await this.fetchActiveCoordinations();
    },
    editTemplate(template: AssignmentCoordinationTemplate) {
      console.log(template);
      this.currentEditTemplate = {
        id: template.id,
        name: template.name,
        description: template.description,
        options: template.options,
        defaults: template.preSelects[0][0],
        isActive: template.isActive,
        assignmentDates: template.assignmentDates.map((date: AssignmentCoordinationDate) => ({
          date: date.date.format(DateTimeFormatter.ISO_LOCAL_DATE),
          description: date.description ?? "",
          times: date.times.map((time) => ({
            description: time.description ?? "",
            time: formatTime(time.time),
            holiday: time.holiday ?? false
          }))
        }))
      }
      this.showCreateCoordination = true;
    },
    async changeActiveStatus(templateId: number, isActive: boolean) {
      await this.backend.query(assignmentCoordination.alterTemplate, {
        id: templateId,
        replacement: {
          isActive
        }
      })
      await this.fetchActiveCoordinations();
    },
  }
})
