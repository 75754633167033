
import Vue from "vue";
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {Backend} from "api-frontend";
import {User} from "mp-common/src/types/entities/user";
import {DateTimeFormatter, LocalDate, LocalTime} from "js-joda";
import {assignmentCoordination} from "mp-common/src/types/api/assignmentCoordinationApi";
import {AssignmentCoordinationOptions} from "mp-common/src/types/entities/assignment-coordination-template";
import {AssignmentCoordinationEditTemplate} from "@/types/assignment-coordination-edit-template";
import {addWeeklyMesses} from "@/helpers/assignment-coordination";

export default Vue.component("AddAssignmentCoordination", {
  name: 'AddAssignmentCoordination',
  mixins: [validationMixin],
  data() {
    return {
      template: this.presentTemplate ?? {
        id: undefined as number | undefined,
        name: "",
        description: "",
        options: [],
        defaults: [],
        isActive: false,
        assignmentDates: [{
          date: LocalDate.now().format(DateTimeFormatter.ISO_LOCAL_DATE),
          description: "",
          times: [{
            description: "",
            time: "",
            holiday: false,
          }]
        }],
      } as AssignmentCoordinationEditTemplate,
      addRange: {
        start: LocalDate.now().format(DateTimeFormatter.ISO_LOCAL_DATE),
        end: LocalDate.now().plusMonths(3).format(DateTimeFormatter.ISO_LOCAL_DATE),
        timeSaturday: LocalTime.of(18, 0).format(DateTimeFormatter.ISO_LOCAL_TIME),
        timeSunday: LocalTime.of(10, 45).format(DateTimeFormatter.ISO_LOCAL_TIME),
      },
      sending: false,
      invalid: false
    }
  },
  props: {
    user: {} as () => User,
    backend: {
      type: Backend,
    },
    presentTemplate: undefined as unknown as () => AssignmentCoordinationEditTemplate | undefined
  },
  validations: {
    template: {
      name: {
        required
      },
      description: {
        required
      }
    }
  },
  methods: {
    getValidationClass(fieldName: string) {
      const field = this.$v.template[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    getOptions() {
      return AssignmentCoordinationOptions
    },
    clearForm() {
      this.$v.$reset()
      this.template.name = "";
      this.template.description = "";
    },
    async createCoordination() {
      this.sending = true
      const result = await this.sendRequest();
      if (result) {
        this.sending = false;
        this.$emit("created");
      } else {
        this.sending = false;
        this.invalid = true;
      }
    },
    async sendRequest() {
      if (this.template.id) {
        return await this.backend.query(assignmentCoordination.alterTemplate, {
          id: this.template.id,
          replacement: {
            assignmentDates: this.template.assignmentDates.map((date) => ({
              ...date,
              date: LocalDate.parse(date.date),
              times: date.times.map((time) => ({description: time.description, holiday: time.holiday ? true : undefined, time: LocalTime.parse(time.time)}))
            })),
            description: this.template.description,
            name: this.template.name,
            isActive: this.template.isActive,
            options: this.template.options,
            preSelects: this.template.assignmentDates.map((date) => date.times.map(() => this.template.defaults.map((item) => !!item)))
          }
        })
      }
      return await this.backend.query(assignmentCoordination.addTemplate, {
        assignmentDates: this.template.assignmentDates.map((date) => ({
          ...date,
          date: LocalDate.parse(date.date),
          times: date.times.map((time: any) => ({...time, time: LocalTime.parse(time.time)}))
        })),
        description: this.template.description,
        name: this.template.name,
        isActive: this.template.isActive,
        options: this.template.options,
        preSelects: this.template.assignmentDates.map((date) => date.times.map(() => this.template.defaults.map((item) => !!item)))
      })
    },
    addDate() {
      this.template.assignmentDates.push({
        date: LocalDate.now().format(DateTimeFormatter.ISO_LOCAL_DATE),
        description: "",
        times: []
      })
    },
    removeDate(index: number) {
      this.template.assignmentDates = this.template.assignmentDates.filter((_, listIndex) => listIndex !== index);
    },
    addTime(dateIndex: number) {
      this.template.assignmentDates[dateIndex].times.push({
        time: "",
        description: "",
        holiday: false
      })
    },
    removeTime(dateIndex: number, timeIndex: number) {
      this.template.assignmentDates[dateIndex].times = this.template.assignmentDates[dateIndex].times.filter((_, listIndex) => listIndex !== timeIndex);
    },
    validate() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.createCoordination()
      }
    },
    addMultiple() {
      this.template.assignmentDates.push(...addWeeklyMesses(
          LocalDate.parse(this.addRange.start),
          LocalDate.parse(this.addRange.end),
          this.addRange.timeSaturday,
          this.addRange.timeSunday
      ));
    }
  },
  template: `
    <form novalidate @submit.prevent="validate" style="display: flex; overflow: hidden">
    <md-card class="md-layout-item" sty>
      <md-card-header>
        <div class="md-title">{{this.template.id ? 'Wunschrunde bearbeiten' : 'Neue Wunschrunde erstellen'}}</div>
      </md-card-header>

      <md-card-content style="overflow-y: auto; max-height: 85%">
        <div class="md-layout-item md-small-size-100">
          <md-field :class="getValidationClass('name')">
            <label for="name">Name</label>
            <md-input name="name" id="name" v-model="template.name"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.template.name.required">Der Name ist erforderlich</span>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field :class="getValidationClass('description')">
            <label for="description">Beschreibung</label>
            <md-input name="description" id="description" v-model="template.description"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.template.description.required">Die Beschreibung ist erforderlich</span>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100">
          <md-field>
            <label for="options">Optionen</label>
            <md-select name="options" id="options" v-model="template.options"
                       :disabled="sending" multiple>
              <md-option v-for="(option, index) in getOptions()" :value="option" :key="index">{{ option }}</md-option>
            </md-select>
          </md-field>
        </div>
        <h3 v-if="template.options.length > 0">Vorauswahl</h3>
        <md-list v-if="template.options.length > 0">
          <md-list-item v-for="(option, index) in template.options" :key="index">
            <md-switch v-model="template.defaults[index]"/>
            <span class="md-list-item-text">{{ option }}</span>
          </md-list-item>
        </md-list>
        <h3>Standart Gottesdienste hinzufügen</h3>
        <div style="display: flex; align-items: center">
          <md-field>
            <label for="time-saturday">Zeit Samstags</label>
            <md-input name="time-saturday" id="time-saturday"
                      v-model="addRange.timeSaturday"
                      :disabled="sending"/>
          </md-field>
          <md-field>
            <label for="time-sunday">Zeit Sonntags</label>
            <md-input name="time-sunday" id="time-sunday"
                      v-model="addRange.timeSunday"
                      :disabled="sending"/>
          </md-field>
          <label for="start-date" style="margin-left: 30px;">StartDate</label>
          <md-datepicker style="margin-right: 20px;" v-model="addRange.start" name="start-date"
                         id="start-date"
                         md-immediately/>
          <label for="end-date" style="margin-left: 30px;">EndDate</label>
          <md-datepicker style="margin-right: 20px;" v-model="addRange.end" name="end-date"
                         id="end-date"
                         md-immediately/>
          <md-button v-on:click="addMultiple()">Hinzufügen</md-button>
        </div>

        <h3 style="margin-left: 7px;">Termine</h3>
        <div v-for="(date, index) in template.assignmentDates" :key="index" class="md-layout-item"
             style="border: 1px solid gray; margin: 5px; padding: 10px;">
          <md-button v-on:click="removeDate(index)">Löschen</md-button>
          <div style="display: flex; align-items: center">
            <md-field>
              <label :for="'date-description-' + index">Beschreibung</label>
              <md-input :name="'date-description-' + index" :id="'date-description-' + index"
                        v-model="date.description"
                        :disabled="sending"/>
            </md-field>
            <label :for="'date-date-' + index" style="margin-left: 30px;"></label>
            <md-datepicker style="margin-right: 20px;" v-model="date.date" :name="'date-date-' + index"
                           :id="'date-date-' + index"
                           md-immediately/>
          </div>
          <div>
            <h3 style="margin-left: 13px;">Zeiten</h3>

            <div v-for="(time, timeIndex) in date.times"
                 style="margin: 0 5px 5px 10px; border: 1px solid gray; padding: 10px;">
              <md-button v-on:click="removeTime(index, timeIndex)">Löschen</md-button>
              <div style="display: flex; align-items: center">
                <md-switch v-model="time.holiday" :disabled="sending"/>
                <span class="md-list-item-text">Mehr Minis</span>
              </div>
              <div style="display: flex; align-items: center; column-gap: 20px;">
                <md-field>
                  <label :for="'date-time-description-' + index">Beschreibung</label>
                  <md-input :name="'date-time-description-' + index" :id="'date-time-description-' + index"
                            v-model="time.description"
                            :disabled="sending"/>
                </md-field>
                <md-field>
                  <label :for="'date-time-time-' + index">Zeit</label>
                  <md-input :name="'date-time-time-' + index" :id="'date-time-time-' + index"
                            v-model="time.time"
                            :disabled="sending"/>
                </md-field>
              </div>
            </div>

            <md-button v-on:click="addTime(index)">Neue Zeit hinzufügen</md-button>
          </div>
        </div>
        <md-button v-on:click="addDate()">Neues Datum hinzufügen</md-button>

      </md-card-content>

      <md-progress-bar md-mode="indeterminate" v-if="sending"/>

      <md-card-actions>
        <md-button v-on:click="$emit('cancel')" :disabled="sending">Abbrechen</md-button>
        <md-button type="submit" class="md-primary" :disabled="sending">Speichern</md-button>
      </md-card-actions>
    </md-card>

    <md-snackbar :md-active.sync="invalid" style="background-color: #FE2E2E"><span
        style="text-align: center; width: 100%">Fehler...</span></md-snackbar>
    </form>
  `
})
