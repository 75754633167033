import {DayOfWeek, LocalDate} from "js-joda";

function getWeeklyMessDates(start: LocalDate, end: LocalDate) {
    const result = new Array<LocalDate>();
    for (var currentDate = start; !currentDate.isAfter(end); currentDate = currentDate.plusDays(1)) {
        if (currentDate.dayOfWeek() == DayOfWeek.SATURDAY || currentDate.dayOfWeek() == DayOfWeek.SUNDAY) {
            result.push(currentDate);
        }
    }
    return result;
}

export function addWeeklyMesses(start: LocalDate, end: LocalDate, timeSaturday: string, timeSunday: string) {
    const messDates = getWeeklyMessDates(start, end);
    return messDates.map(date => {
        if (date.dayOfWeek() == DayOfWeek.SATURDAY) {
            return {
                date: date.toString(),
                description: "",
                times: [
                    {
                        description: "Vorabendmesse",
                        time: timeSaturday,
                        holiday: false,
                    }
                ]
            }
        }
        return {
            date: date.toString(),
            description: "",
            times: [
                {
                    description: "Familiengottesdienst",
                    time: timeSunday,
                    holiday: false,
                }
            ]
        }
    });
}